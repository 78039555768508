<template>
  <div>
    <div v-if="!is_sup">
      <form class="input-group" @submit.prevent="access(pin)">
        <input
          class="form-control"
          placeholder="PIN"
          type="password"
          v-model="pin"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="access(pin)"
          >
            Edit Access !
          </button>
        </div>
      </form>
      <br />
      <!-- <personal-desh /> -->
    </div>

    <div>
      <div class="row">
        <div class="col-12 mb-1">
          <form @submit.prevent="gettran()">
            <div class="row">
              <div class="col-6 mb-1">
                <label for="fromdate">From date</label>
                <b-form-datepicker
                  id="fromdate"
                  v-model="postdata.fromdate"
                  class="mb-1"
                />
              </div>
              <div class="col-6 mb-1">
                <label for="todate">To date</label>
                <b-form-datepicker
                  id="todate"
                  v-model="postdata.todate"
                  class="mb-1"
                />
              </div>
            </div>

            <div class="input-group mb-1">
              <!-- basic search -->
              <input
                class="form-control"
                placeholder="Mobile Number"
                list="user-list"
                type="text"
                v-model="postdata.number"
              />

              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="gettran()"
                >
                  Search !
                </button>
              </div>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="reset()"
                >
                  Reset !
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="text-right mr-1" v-if="is_sup">
        <b-badge
          class="mb-1"
          variant="primary"
          href="#"
          @click="modaldata('new')"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span> Add </span>
        </b-badge>
      </div>
      <div v-if="is_result" hidden>
        <!-- success -->
        <b-alert variant="success" show>
          <h4 class="alert-heading">Success</h4>
          <div class="alert-body">
            <div class="row">
              <div class="col-12">
                <span>Message: {{ result.message }}</span>
              </div>
            </div>
            <div class="row" v-if="result.is_withdraw == false">
              <div class="col-12">
                <span> DTM ID: {{ result.dtm_id }}</span>
              </div>
              <div class="col-12">
                <span> Full Name: {{ result.fullName }}</span>
              </div>
              <div class="col-12">
                <span> Mobile Number: {{ result.mobile1 }}</span>
              </div>
              <div class="col-12" v-if="result.sms_response">
                <span> SMS Status: </span>
                <b
                  style="color: red"
                  v-if="result.sms_response.api_response_code == 400"
                >
                  {{ result.sms_response.api_response_message }}</b
                >
                <b v-else> {{ result.sms_response.api_response_message }}</b>
              </div>
            </div>
          </div>
        </b-alert>
      </div>
      <div>
        <b-overlay
          id="overlay-background"
          :show="loder"
          variant="transparent"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <b-table small :fields="fields" :items="items" responsive="sm">
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(is_auth)="data">
              <b-badge
                class="mr-1"
                href="#"
                @click="modaldata(data.index)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal
                variant="warning"
                v-if="is_sup"
              >
                <feather-icon icon="EditIcon" class="mr-0" />
              </b-badge>
              <b-badge class="mr-1" v-else>
                <feather-icon icon="EditIcon" class="mr-0" />
              </b-badge>
            </template>
            <template #cell(status)="data">
              <b-badge class="mr-1" variant="success" v-if="data.value">
                <feather-icon icon="GitBranchIcon" class="mr-25" />
                <span> Paid </span>
              </b-badge>
              <b-badge class="mr-1" variant="danger" v-else>
                <feather-icon icon="GitPullRequestIcon" class="mr-25" />
                <span>Unpaid</span>
              </b-badge>
            </template>

            <!-- A virtual composite column -->
            <template #cell(event_date)="data">
              <div>
                <b> {{ data.value }} </b>
              </div>
            </template>

            <!-- Optional default data cell scoped slot -->
            <!-- <template #cell(tran_date)="data">
              <b-badge pill variant="dark">{{ data.value }}</b-badge>
            </template> -->
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-overlay>
      </div>
    </div>
    <!-- model view  -->
    <b-modal
      id="modal"
      ref="refmodal"
      centered
      size="lg"
      :title="`Food Sponsor Details ( ID: ${info.id} )`"
      @ok="adjustment"
    >
      <b-card-text>
        <div class="row">
          <div class="col-2 mb-1">
            <b-badge pill variant="success" v-if="info.status">
              <span>Paid</span>
            </b-badge>
            <b-badge pill variant="danger" v-else>
              <span>Unpaid</span>
            </b-badge>
          </div>
          <div class="col-2 mb-1">
            <b-badge pill variant="success" v-if="info.status">
              <span>SMS Active</span>
            </b-badge>
            <b-badge pill variant="danger" v-else>
              <span>SMS Inactive</span>
            </b-badge>
          </div>
          <!-- 
          <div class="col-2 mb-1">
            <b-badge variant="success" v-if="info.is_auth">
              <feather-icon icon="ShieldIcon" class="mr-0" />
            </b-badge>
            <b-badge variant="danger" v-else>
              <feather-icon icon="ShieldOffIcon" class="mr-0" />
            </b-badge>
          </div> -->
          <div class="col-6 mb-1">
            <b-badge variant="dark" class="badge-glow">
              {{ info.event_date }}
            </b-badge>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1">
            <label for="envetdate">Event date</label>
            <b-form-datepicker
              id="envetdate"
              v-model="info.event_date"
              class="mb-1"
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="foodlist">Food Item Name</label>
            <input
              class="form-control"
              placeholder="Food Item Name"
              list="food-list"
              type="foodlist"
              v-model="info.item"
              required
            />
            <datalist id="food-list">
              <option value="Egg Khichuri"></option>
              <option value="Chicken Khichuri"></option>
              <option value="Chicken Tehari"></option>
              <option value="Mutton Tehari"></option>
              <option value="Plain Rice with Vegetables"></option>
            </datalist>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="fname">Full Name</label>
            <input
              class="form-control"
              placeholder="Full Name"
              type="fname"
              v-model="info.fullName"
              required
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="trandate">Contact Number</label>
            <input
              class="form-control"
              placeholder="+88018xxxxxxxx"
              type="text"
              id="trandate"
              v-model="info.mobile"
              required
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="area">Area</label>
            <input
              class="form-control"
              list="area-list"
              placeholder="Area"
              type="area"
              v-model="info.event_area"
            />
            <datalist id="area-list">
              <option value="Any Place"></option>
              <option value="Kamalapur, Valo Kajer Hotel"></option>
            </datalist>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="dtmids">DTM ID</label>
            <input
              class="form-control"
              placeholder="DTM ID"
              type="text"
              id="dtmids"
              v-model="info.dtm_id"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Total Amount</div>
            </div>
            <input
              class="form-control"
              placeholder="total packet amount"
              type="number"
              v-model="info.total_amount"
            />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Total Meal</div>
            </div>
            <input
              class="form-control"
              placeholder="0"
              type="number"
              v-model="info.packet"
            />
            <div class="input-group-append">
              <div class="input-group-text">Packet</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row ml-1">
          <!-- success -->
          <div class="mr-1">
            <b-card-text class="mb-0"> Payment </b-card-text>
            <b-form-checkbox
              v-model="info.status"
              class="custom-control-success"
              name="check-button"
              switch
            />
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BFormDatepicker,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSpinbutton,
  BFormCheckbox,
  BFormSelect,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
// import PersonalDesh from "../extensions/card-statistic/CardStatisticsGroup.vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import vSelect from "vue-select";
import axios from "axios";
// import AppTourVue from "../../@core/components/app-tour/AppTour.vue";

export default {
  name: "adjustment",
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormDatepicker,
    vSelect,
    // PersonalDesh,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    BFormCheckbox,
    BFormSelect,
    BAlert,
    BOverlay,
  },
  directives: {
    "height-fade": heightFade,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loder: true,
      today: "",
      tomorrow: "",
      postdata: {
        fromdate: "",
        todate: "",
        number: "",
      },
      data: "",
      pin: "",
      is_data: false,
      is_sup: false,
      is_w: false,
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "event_date", label: "Event Date" },
        { key: "fullName", label: "Full Name" },
        { key: "mobile", label: "Mobile" },
        // A regular column
        { key: "item", label: "Item" },
        // A virtual column made up from two fields
        { key: "total_amount", label: "Total Amount" },
        // A regular column
        { key: "packet", label: "Total Packet" },
        { key: "dtm_id", label: "DTM ID" },
        { key: "created_by", label: "Created By" },
        { key: "status", label: "Status" },
        { key: "is_auth", label: "Action" },
      ],
      items: [],
      info_id: "",
      info: {
        event_area: "",
        event_date: "",
        fullName: "",
        is_auth: false,
        is_payment: false,
        item: "",
        mobile: "+880",
        packet: 0,
        status: true,
        total_amount: "0",
      },
      dtm_info: {
        dtm_profile: {
          fullName: "",
        },
      },
      ip: {},
      predata: [],
      predtmdata: [],
      preregdata: [],
      total: "৳0",
      geo: "",
      is_result: false,
      result: {},
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "0/0",
          subtitle: "Total/Monthly Transaction",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "0/0",
          subtitle: "Total/Monthly DTM Registration",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "AwardIcon",
          color: "light-success",
          title: "৳0",
          subtitle: "This Month`s Collection",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-danger",
          title: "৳0",
          subtitle: "Panding Amount",
          customClass: "",
        },
      ],
    };
  },
  computed: {
    statusChack() {
      const that = this;
      if (that.status == true) {
        return "Active";
      } else if (that.status == false) {
        return "Inactive";
      } else {
        return "";
      }
    },
    buttonStatus() {
      const that = this;
      if (that.status == true) {
        return true;
      } else if (that.status == false) {
        return false;
      } else {
        return false;
      }
    },
    chack() {
      const that = this;
      if (that.formdata.dtm_amount == 0 && that.formdata.donate_amount == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    adjustment() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      that.info.created_by = userdata.username;
      that.info.info_id = that.info_id;
      if (
        that.info &&
        that.info.item &&
        that.info.fullName &&
        that.info.event_date &&
        that.info.packet
      ) {
        if (that.info.mobile) {
          this.$swal({
            title: "Are you sure?",
            text: "Yes, Submit this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, submit it!",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm() {
              that.$http
                .post(`/v1/fms/foodsponsor/post/`, that.info)
                .then((response) => {
                  console.log(response);
                  that.result = response.data;
                  that.gettran();
                })
                .catch((error) => {
                  // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                  console.log(error);
                });
            },
          }).then((result) => {
            if (result.value) {
              this.$swal({
                icon: "success",
                title: "Created!",
                text: "Payment has been Created.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              that.reset();
            }
          });
        } else {
          let variant = "danger";
          this.$bvToast.toast("Mobile number is Required", {
            title: `Error`,
            variant,
            solid: true,
          });
        }
      } else {
        let variant = "danger";
        this.$bvToast.toast("All Fields is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    dtm_month(input) {
      const date1 = new Date(input);
      let Y1 = date1.getFullYear();
      let M1 = date1.getMonth() + 1;
      let D1 = date1.getDate();
      let d1 = D1 + 1;
      const date = new Date();
      let Y = date.getFullYear();
      let M = date.getMonth() + 1;
      let D = date.getDate();
      let d = D + 1;
      this.today = Y + "-" + M + "-" + D;
      this.tomorrow = Y + "-" + M + "-" + d;
      console.log(this.today, this.tomorrow);
      if (Y1 == Y && M1 == M && D1 == D) {
        return 1;
      } else if (Y1 == Y && M1 == M && d1 == d) {
        return 2;
      } else {
        return 0;
      }
    },
    modaldata(index) {
      const that = this;
      that.info = {};
      that.info_id = index;
      // if (that.items[index].tran_val == 1) {
      //   that.getdtm_info(that.items[index].dtm_id);
      // } else {
      //   that.dtm_info = {};
      // }
      if (index != "new") {
        this.info = this.items[index];
      } else {
        that.info = {
          event_area: "",
          event_date: "",
          fullName: "",
          is_auth: false,
          is_payment: false,
          item: "",
          mobile: "+880",
          packet: 0,
          status: true,
          total_amount: "0",
        };
      }
      // this.getip(this.items[index].ip_address);
    },
    access(pin) {
      if (!pin == "") {
        this.$http
          .post(`/dtm/auth/pay/checkpin/all/`, {
            pin: pin,
          })
          .then((response) => {
            this.is_sup = response.data.is_sup;
            if (response.data.message == false) {
              let variant = "success";
              this.$bvToast.toast("PIN Verified ", {
                title: `Access Success`,
                variant,
                solid: true,
              });
            } else {
              let variant = "warning";
              this.$bvToast.toast("PIN Incorrect", {
                title: `Access Faild`,
                variant,
                solid: true,
              });
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("PIN is Required", {
          title: `Access Faild`,
          variant,
          solid: true,
        });
      }
    },
    postauth(id) {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!id == "") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Submit!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            that.$http
              .post(`/v1/fms/foodsponsor/postauth/`, {
                id: id,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Success!",
              text: "Event has been Auth/Unauth.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            that.gettran();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("ID is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    updatetran(id) {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.selected);

      if (!id == "") {
        this.$swal({
          title: "Update Money Recept Number",
          input: "text",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Update",
          showLoaderOnConfirm: true,
          preConfirm(number) {
            that.$http
              .post(`/dtm/auth/pay/updateTran/${id}/`, {
                gateway_tran_id: number,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: `Updated`,
              // imageUrl: result.value.avatar_url,
              customClass: { confirmButton: "btn btn-primary" },
            });
            that.gettran();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Transaction Id is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    // pre() {
    //   this.$http
    //     .get(`/dtm/sundy/pay/getpresundy/6/`)
    //     .then((response) => {
    //       this.predata = response.data;
    //     })
    //     .catch((error) => console.log(error));
    // },
    // getdtmdata() {
    //   this.$http
    //     .get(`/dtm/reg/pay/dtmlist/${this.dtm_id || 0}/`)
    //     .then((response) => {
    //       this.predtmdata = response.data;
    //     })
    //     .catch((error) => console.log(error));
    // },
    // getregdata() {
    //   this.$http
    //     .get(`/dtm/new/reg/${this.reg_id || 0}/`)
    //     .then((response) => {
    //       this.preregdata = response.data;
    //     })
    //     .catch((error) => console.log(error));
    // },
    gettran() {
      var userdata = JSON.parse(localStorage.getItem("userData"));
      this.is_result = false;
      if (!this.postdata == "") {
        this.loder = true;
        this.$http
          .post(`/v1/fms/foodsponsor/getfoodsponsor/`, this.postdata)
          .then((response) => {
            this.items = response.data;

            this.loder = false;
          })
          .catch((error) => console.log(error));
        this.is_data = true;
        this.loder = false;
      } else {
        let variant = "danger";
        this.$bvToast.toast("Query Data is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    reset() {
      const that = this;
      that.postdata.fromdate = "";
      that.postdata.todate = "";
      // that.postdata.number = "";
      // that.postdata.account = "";
      // that.postdata.trx = "";
      // that.postdata.total_amount = "";
      that.info = {
        event_area: "",
        event_date: "",
        fullName: "",
        is_auth: false,
        is_payment: false,
        item: "",
        mobile: "+880",
        packet: 0,
        status: true,
        total_amount: "0",
      };
      // that.dtm_id = "";
      // that.reg_id = "";
      // that.tran_val = 1;
      // that.month = 0;
      // that.dtm_pay = 0;
      // that.reg_pay = 200;
      // that.is_result = false;
      // that.is_w = false;
      this.is_data = false;
      // this.pre();
      // this.getregdata();
      // this.getdtmdata();
      this.selfgetip();
    },
    dtmreset() {
      const that = this;
      that.month = 0;
      that.dtm_pay = 0;
      that.reg_pay = 200;
    },
    async getip(ip) {
      await axios
        .get(`https://get.geojs.io/v1/ip/geo/${ip}.json`)
        .then((response) => {
          this.ip = response.data;
        })
        .catch((error) => console.log(error));
    },
    selfgetip() {
      axios
        .get("https://get.geojs.io/v1/ip/geo.json")
        .then((response) => {
          this.geo = response.data;
        })
        .catch((error) => console.log(error));
    },
    getdfs() {
      this.loder = true;
      this.$http
        .get(`/v1/fms/foodsponsor/0/`)
        .then((response) => {
          this.items = response.data;

          this.loder = false;
        })
        .catch((error) => {
          console.log(error);

          this.loder = false;
        });
    },
  },
  created() {
    // this.gettran();
    // this.dtm_month();
    // this.pre();
    // this.getregdata();
    this.getdfs();
    // this.getdtmdata();
    this.selfgetip();
    var userdata = JSON.parse(localStorage.getItem("userData"));
    this.is_sup = userdata.is_sup;
  },
};
</script>
